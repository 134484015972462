import React, {useState} from "react";


const SatzungPage = () => {


    return (
        <div className={'grid ui'} style={{minHeight: '600px'}}>
            <div className={'row centered page-wrapper'}>
                <div className={'fourteen wide column'} style={{textAlign:'center'}}>
                    <iframe src="/downloads/Calendula_Satzung_ab2022.pdf#toolbar=0" width="100%" height="1000px"/>
                    <a  href={'/downloads/Calendula_Satzung_ab2022.pdf'} download>
                        <button className={'button-1'}>
                            Satzung Herunterladen
                        </button>
                    </a>

                </div>
            </div>

        </div>

    )
}

export default SatzungPage